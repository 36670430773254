import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Hero from '../components/heroBanner/heroBanner'
import HtmlPrinter from '../components/htmlPrinter/htmlPrinter'
import style from '../assets/css/infoPage.module.css';

const UsPrivacyPage = (props) => {
  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'PRIVACY_META_TITLE' })}
        url={props.location.pathname}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        description={intl.formatMessage({ id: 'PRIVACY_META_DESCRIPTION' })}
      />
      <Hero title={intl.formatMessage({ id: 'US_PRIVACY_TEXT1' })} />
      <section className={`${style.infoSection}`}>
        <article>
          <p className={style.marginS}>
            <HtmlPrinter
              content={intl.formatMessage({ id: 'US_PRIVACY_TEXT2' })}
            />
          </p>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT3' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT4' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT5' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT6' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT7' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT8' })}
          </p>
        </article>
      </section>
      <section className={`${style.infoSection}`}>
        <h2 className={style.marginS}>
          {intl.formatMessage({ id: 'US_PRIVACY_TEXT9' })}
        </h2>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT10' })}
          </h3>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT11' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT12' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT13' })}
          </h3>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT14' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT15' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT16' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT17' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT18' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT19' })}</p>
            </li>
          </ul>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT20' })}
          </p>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT21' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT22' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT23' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT24' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT25' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT26' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT27' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT28' })}
                />
              </p>
            </li>
          </ul>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT29' })}
          </p>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT30' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT31' })}</p>
            </li>
          </ul>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT32' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT33' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT34' })}
          </h3>
          <h4 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT35' })}
          </h4>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT36' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT37' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT38' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT39' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT40' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT41' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT42' })}</p>
            </li>
          </ul>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT43' })}
          </p>
          <h4 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT44' })}
          </h4>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT45' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT46' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT47' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT48' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT49' })}</p>
              <ul>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT50' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT51' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT52' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT53' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT54' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT55' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT56' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT57' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT58' })}</p>
                </li>
                <li>
                  <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT59' })}</p>
                </li>
              </ul>
            </li>
          </ul>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT60' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT61' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginXS}`}>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT62' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT63' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT64' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT65' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT66' })}</p>
            </li>
            <li>
              <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT67' })}</p>
            </li>
          </ul>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT68' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT69' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT70' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT71' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT72' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT73' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT74' })}
          </h3>
          <h4 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT75' })}
          </h4>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT76' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT77' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT78' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT79' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT80' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT81' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT82' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT83' })}
          </p>
          <p className={style.marginXS}>
            <HtmlPrinter
              content={intl.formatMessage({ id: 'US_PRIVACY_TEXT84' })}
            />
          </p>
          <h4 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT85' })}
          </h4>
          <h5 className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT86' })}
          </h5>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT87' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT88' })}
          </p>
          <h5 className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT89' })}
          </h5>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT90' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT91' })}
          </p>
          <h5 className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT92' })}
          </h5>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT93' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT94' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT95' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT96' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT97' })}
          </p>
          <h4 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT98' })}
          </h4>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT99' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT100' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT101' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT102' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT103' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT104' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT105' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT106' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT107' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT108' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT109' })}
          </p>
          <ul className={`${style.privacyList} ${style.marginS}`}>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT110' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT111' })}
                />
              </p>
            </li>
            <li>
              <p>
                <HtmlPrinter
                  content={intl.formatMessage({ id: 'US_PRIVACY_TEXT112' })}
                />
              </p>
            </li>
          </ul>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT113' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT114' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT115' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT116' })}
          </p>
          <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT117' })}</p>
          <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT118' })}</p>
          <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT119' })}</p>
          <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT120' })}</p>
          <p>{intl.formatMessage({ id: 'US_PRIVACY_TEXT121' })}</p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT122' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT123' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT124' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT125' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT126' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT127' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT128' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT129' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT130' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT131' })}
          </p>
        </article>
      </section>
      <section className={`${style.infoSection}`}>
        <h2 className={style.marginS}>
          {intl.formatMessage({ id: 'US_PRIVACY_TEXT132' })}
        </h2>
        <article>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT133' })}
          </p>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT134' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT135' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT136' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT137' })}
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT138' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT139' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT140' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT141' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT142' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT143' })}
          </p>
          <p className={style.marginM}>
            <HtmlPrinter
              content={intl.formatMessage({ id: 'US_PRIVACY_TEXT144' })}
            />
          </p>
        </article>
        <article>
          <h3 className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT145' })}
          </h3>
          <p className={style.marginS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT146' })}
          </p>
          <p className={style.marginXS}>
            {intl.formatMessage({ id: 'US_PRIVACY_TEXT147' })}
          </p>
          <p className={style.marginXS}>
            <HtmlPrinter
              content={intl.formatMessage({ id: 'US_PRIVACY_TEXT148' })}
            />
          </p>
        </article>
        <div className={style.marginM}/>
      </section>
    </Layout>
  )
}
export default UsPrivacyPage
